import React from "react"
export default function Footer() {
  return (
    <section className="section footer has-background-secondary">
      <div className="footer-inner" style={{display: "flex", alignItems: "center", height: "100%"}}>
        <div className="columns">
          <div className="column is-one-fifth">
            <img className="footer-logo" src="assets/prairie-process-serving-logo-white.png" alt="white logo" />
          </div>
          <div className="column is-one-quarter">
            <p className="footer-column-title">Hours of Operation</p>
            <p>8:00 a.m. to 4:30 p.m.<br/>Monday to Friday</p>          
            <p>For emergencies after the hours noted above please call <a className="transition" href="tel:+3062609861">306.260.9861</a></p>
            <p>We accept all modes of payment.</p>
          </div>
          <div className="column is-one-quarter">
            <p className="footer-column-title">Mailing Address</p>
            <p>Prairie Process Serving</p>
            <p>PO Box 233<br/>
              Asquith<br/>
              SK S0K 0J0<br/>
            </p>
            <p>Should you need to courier documents to Saskatoon please call our office and we will provide you with an alternative address.</p>
          </div>
          <div className="column">
            <p className="footer-column-title">Contact</p>
            <p><a className="transition" href="tel:+3062609861">306.260.9861</a></p>
            <p><a className="transition" href="tel:+3063290009">Office: 306.329.0009</a></p>
            <p className="footer-email"><a className="transition" style={{"overflow-wrap":"anywhere"}} href="mailto:service@prairieprocessserving.com">service@prairieprocessserving.com</a></p>
            <p className="has-text-white"><a className="fb transition" target="_blank" href="https://www.facebook.com/prairieprocessserving"><svg xmlns="http://www.w3.org/2000/svg" width="15.469" height="30" viewBox="0 0 15.469 30"><path d="M4.494,30V16.582H0V11.25H4.494v-4.2C4.494,2.484,7.283,0,11.355,0a37.73,37.73,0,0,1,4.113.211V4.98H12.645C10.43,4.98,10,6.035,10,7.576V11.25h5l-.686,5.332H10V30"/></svg></a></p>
          </div>
        </div>
      </div>
    </section>
  )
}