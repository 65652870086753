import React from "react"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";
import logo from "../assets/prairie-process-serving-logo.png"

export default function Header() {

  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <div>
      <Helmet>
        <html className="has-navbar-fixed-top"/>
      </Helmet>
      <header>
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/" style={{paddingTop:"20px", paddingBottom:"20px"}}>
              <div style={{position: "relative"}}>
                <img alt="logo" src={logo} />
              </div>
            </Link>

            <a role="button" className={`navbar-burger burger ${mobileNavOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>

              <div className="navbar-burger-container">
                {!mobileNavOpen &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.391" height="22.298" viewBox="0 0 30.391 22.298"><g transform="translate(179.731 83.426)"><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -83.426)"/><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -74.037)"/><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -64.648)"/></g></svg>
                }
                {mobileNavOpen &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.979" height="23.979" viewBox="0 0 23.979 23.979"><g transform="translate(494.601 -854.159)"><rect className="a" width="30.391" height="3.521" transform="translate(-494.601 875.649) rotate(-45)"/><rect className="a" width="30.391" height="3.521" transform="translate(-492.112 854.159) rotate(45)"/></g></svg>
                }
              </div>
            </a>
          </div>

          <div className={`navbar-menu ${mobileNavOpen ? "is-active" : ""}`} >
            <div className="navbar-end">
              <Link to="#services" className="navbar-item transition">
                Services
              </Link>
              <a href="mailto:service@prairieprocessserving.com" className="navbar-item transition">
                Contact
              </a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}